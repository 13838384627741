import list from './../assets/img/list.webp'
import { truncateAddress } from "../utils"
import config from "../config"
import api from '../fetch'
import { useEffect, useState } from 'react'

import defaultAvatar from './../assets/img/defaultAvatar.png'

export default function AdminReq ({currentAccount,}) {

  const [isBoxReqList, setIsBoxReqList] = useState([
    {
      name: 'Loading', 
      id: '0', 
      price: 0, 
      quantity: 0, 
      status: 'none', 
      user: {
        name: 'Loading', 
        displayRole: 'Loading', 
        ref: false, 
        avatar: null
      },
      drop: {
        createdDate: Date.now(),
        name: "Loading"
      },
      item: {
        name: "",
      }
    }
  ])

  const [approved, setApproved] = useState(0)
  const [declined, setDeclined] = useState(0)

  useEffect(() => {
    api.addListener('updateBoxRequest', (updateBoxRequest) => {
      if (updateBoxRequest.new) {
        BoxReqList()
      } else {
        setIsBoxReqList(oldIsBoxReqList => oldIsBoxReqList.map(x => x.id == updateBoxRequest.id ? updateBoxRequest : x))
      }
    })
    return () => api.removeListener('updateBoxRequest')
  }, [])

  async function BoxReqList() {
    await api.get(`/admin/box/list?from=${currentAccount}`).then(res => {
      setApproved(0)
      setDeclined(0)
      setIsBoxReqList(res.reverse())
      console.log('box-req', res)
    })
  }

  useEffect(() => {
    BoxReqList()
  }, [])
  

  useEffect(() => {
    isBoxReqList.map((x, i) => {
      if (x.status === "approved") {
        setApproved((oldApproved) => {return oldApproved + 1})
      } else if (x.status === 'declined') {
        setDeclined((oldDeclined) => {return oldDeclined + 1})
      }
      return x
    })
  }, [isBoxReqList])
  
  return (
      <div className='admin__req'>
        <div className="admin__req-top">
          <div className="admin__req-left">
            <div className="admin__req-all">
              <img src={list} alt="" className="admin__players-img" />
              <p className="admin__req-subtitle">
                All requests: <span className="admin__req-number">{isBoxReqList.length}</span>
              </p>
            </div>
            <p className="admin__req-descr">
              Approved: <span className="admin__req-number">{approved}</span>
            </p>
            <p className="admin__req-descr">
              Declined: <span className="admin__req-number">{declined}</span>
            </p>
          </div>
          <div className="admin__req-right">
            <a href={`${config.apiEndpoint}/admin/drops/download`} className="admin__req-btn">
              &darr; Download
            </a>
          </div>
        </div>
        <div className="admin__req-bottom">
          <ul className="admin__req-list req-list">
            {isBoxReqList.map((x, i) => {
              return (
                <li key={i} className="admin__req-item req-list__item">
                  <div className="req-list__left">
                    <div className="req-list__top">
                      <div className="req-list__first">
                        <p className="req-list__index">
                          #{i + 1}
                        </p>
                        <div className="req-list__avatar" style={{
                          backgroundImage: `url(${x.user.avatar != null ? x.user.avatar : defaultAvatar})`
                        }}/>
                        <p className="req-list__name">
                          {x.user.name == null ? 'noname' : x.user.name}
                        </p>
                        <p className="req-list__email">
                          {x.user != null && x.user.email != null && x.user.email} {x.user != null && x.user.verified != null && x.user.verified === true && `(verified)`}
                        </p>
                      </div>
                      <div className="req-list__info"/>
                      <div className="req-list__second">
                        <p className="req-list__address">
                          {x.item.name}
                        </p>
                      </div>
                      <div className="req-list__third">
                        <p className="req-list__amount">
                          Status: <span className="req-list__number" style={{
                            color: x.status === 'approved' ? '#87ff69' : (x.status === 'cancelled' || x.status === 'declined'  ? '#ff3232' : '#ffffff')
                          }} >{x.status}</span>
                        </p>
                      </div>
                      <div className="req-list__fourth">
                        <p className="req-list__ref">
                          Referral: <span className="req-list__number req-list__ref-status" style={{
                            color: x.user.ref ? '#87ff69' : '#ff3232'
                          }}>{x.user.ref ? 'Yes' : 'No'}</span>
                        </p>
                      </div>
                    </div>
                    <div className="req-list__bottom">
                      <div className="req-list__first">
                        {/* <p className="req-list__req">
                          Request:
                        </p> */}
                        <p className="req-list__role">
                          {x.drop.name} (<span className="req-list__role">{new Date(x.date).toLocaleDateString('en-US', {month: "short", day: "numeric", year: "numeric"})}</span>)
                        </p>
                      </div>
                      <div className="req-list__info">
                        <p className="req-list__amount">
                          Payout amount: {x.payout != null ? x.payout : 0} USDC
                        </p>
                        <p className="req-list__amount">
                          Mentor: {(x.user.ref)}
                        </p>
                      </div>
                      <div className="req-list__second">
                        <p className="req-list__amount">
                          Amount: <span className="req-list__number">x{x.quantity}</span>
                        </p>
                      </div>
                      <div className="req-list__third">
                        <p className="req-list__price">
                          Price: <span className="req-list__number">{x.price} USDC</span>
                        </p>
                      </div>
                      <div className="req-list__fourth">
                        <p className="req-list__total">
                          Total: <span className="req-list__number req-list__total-number">{x.quantity * x.price} USDC</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="req-list__right">
                    <button className={`req-list__btn req-list__btn-yes ${x.status === 'approved' || x.status === 'declined' ? 'req-list__btn--active' : ''}`} style={{
                      opacity: x.status === 'none' || x.status === 'waiting' ? '1' : (x.status === "approved" ? '0.7' : '0.3')
                    }} onClick={async () => {
                      if (x.status === 'none' || x.status === 'waiting') {
                        await api.post(`/admin/drops/setStatus?from=${currentAccount}`, {
                          id: x._id,
                          status: 2
                        }, null).then(res => {
                          console.log(res)
                        })
                        await BoxReqList()
                      }
                    }}>
                      Approve
                    </button>
                    <button className={`req-list__btn req-list__btn-no ${x.status === 'declined' || x.status === 'approved' ? 'req-list__btn--active' : ''}`} style={{
                      opacity: x.status === 'none' || x.status === 'waiting' ? '1' : (x.status === "declined" ? '0.7' : '0.3')
                    }} onClick={async () => {
                      if (x.status === 'none' || x.status === 'waiting') {
                        await api.post(`/admin/drops/setStatus?from=${currentAccount}`, {
                          id: x._id,
                          status: 3
                        }, null).then(res => {
                          console.log(res)
                        })
                        await BoxReqList()
                      }
                    }}>
                      Decline
                    </button>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
  )
}