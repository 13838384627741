/* global BigInt */

import { useState, useEffect, useRef } from "react"

import list from './../assets/img/list.webp'
import flagImg from './../assets/img/flag.webp'
import starImg from './../assets/img/star.webp'
import walletImg from './../assets/img/wallet.webp'
import boxImg from './../assets/img/box.jpg'

import { gsap } from "gsap";
import api from "../fetch"
import DropsModal from "./../components/DropsModal"
import buyNft from "../contracts/BuyNFT"
import SuccessAdminDrop from "../components/SuccessAdminDrop"

export default function AdminDrops ({BoxsLoading, boxTopList, setBoxTopList, setDateForModal, isDropList, setIsModalDrops, q, currentAccount, service }) {

  async function BoxTopReq() {
    await api.get(`/drops/get?from=${currentAccount}&admin`, service.authCallback).then(res => {
      console.log(res)
      if (!res.error || res != null) {
      setBoxTopList(res)
      }
      console.log('box-top', res)
    })
  }

  useEffect(() => {
    BoxTopReq()
    BoxsLoading()
  }, [])

  const [name, setName] = useState(((boxTopList == null || boxTopList.error) && boxTopList.length > 0) || isDropList.length === 0 ? 'Loading...' : boxTopList.name)
  const [status, setStatus] = useState(((boxTopList == null || boxTopList.error) && boxTopList.length > 0) || isDropList.length === 0 ? false : boxTopList.status)
  const [createdDate, setCreatedDate] = useState(((boxTopList == null || boxTopList.error) && boxTopList.length > 0) || isDropList.length === 0 ? new Date().toISOString().slice(0, 10) : new Date(boxTopList.createdDate == null ? Date.now() : boxTopList.createdDate).toISOString().slice(0, 10))
  const [finishDate, setFinishDate] = useState(((boxTopList == null || boxTopList.error) && boxTopList.length > 0) || isDropList.length === 0 ? new Date().toISOString().slice(0, 10) : new Date(boxTopList.finishDate == null ? Date.now() : boxTopList.finishDate).toISOString().slice(0, 10))
  const [currency, setCurrency] = useState(((boxTopList == null || boxTopList.error) && boxTopList.length > 0) || isDropList.length === 0 ? 'usdt' : boxTopList.currency)
  const [minNeed, setMinNeed] = useState(((boxTopList == null || boxTopList.error) && boxTopList.length > 0) || isDropList.length === 0 ? 0 : boxTopList.minNeed)
  const [maxNeedTotal, setMaxNeedTotal] = useState(((boxTopList == null || boxTopList.error) && boxTopList.length > 0) || isDropList.length === 0 ? 0 : boxTopList.maxNeedTotal)
  const [nextSaleDate, setNextSaleDate] = useState(((boxTopList == null || boxTopList.error) && boxTopList.length > 0) || isDropList.length === 0 ? new Date().toISOString().slice(0, 10) : new Date(boxTopList.nextSaleDate == null ? Date.now() : boxTopList.nextSaleDate).toISOString().slice(0, 10))
  const [boxNames, setBoxNames] = useState(isDropList.length === 0 ? ['Loading', 'Loading', 'Loading'] : isDropList.map(x => x.name))
  const [boxDescrs, setBoxDescrs] = useState(isDropList.length === 0 ? ['Loading', 'Loading', 'Loading'] : isDropList.map(x => x.description))
  const [boxVillages, setBoxVillages] = useState(isDropList.length === 0 ? [0, 0, 0] : isDropList.map(x => x.villages))
  const [boxCharacters, setBoxCharacters] = useState(isDropList.length === 0 ? [0, 0, 0] : isDropList.map(x => x.characters))

  useEffect (() => {
    console.log('boxTopList', boxTopList)
    setName(((boxTopList == null || boxTopList.error) && boxTopList.length > 0) || isDropList.length === 0 ? 'Loading...' : boxTopList.name)
    setStatus(((boxTopList == null || boxTopList.error) && boxTopList.length > 0) || isDropList.length === 0 ? false : boxTopList.status)
    setCreatedDate(((boxTopList == null || boxTopList.error) && boxTopList.length > 0) || isDropList.length === 0 ? new Date().toISOString().slice(0, 10) : new Date(boxTopList.createdDate == null ? Date.now() : boxTopList.createdDate).toISOString().slice(0, 10))
    setFinishDate(((boxTopList == null || boxTopList.error) && boxTopList.length > 0) || isDropList.length === 0 ? new Date().toISOString().slice(0, 10) : new Date(boxTopList.finishDate == null ? Date.now() : boxTopList.finishDate).toISOString().slice(0, 10))
    setCurrency(((boxTopList == null || boxTopList.error) && boxTopList.length > 0) || isDropList.length === 0 ? 'usdt' : boxTopList.currency)
    setMinNeed(((boxTopList == null || boxTopList.error) && boxTopList.length > 0) || isDropList.length === 0 ? 0 : boxTopList.minNeed)
    setMaxNeedTotal(((boxTopList == null || boxTopList.error) && boxTopList.length > 0) || isDropList.length === 0 ? 0 : boxTopList.maxNeedTotal)
    setNextSaleDate(((boxTopList == null || boxTopList.error) && boxTopList.length > 0) || isDropList.length === 0 ? new Date().toISOString().slice(0, 10) : new Date(boxTopList.nextSaleDate == null ? Date.now() : boxTopList.nextSaleDate).toISOString().slice(0, 10))
  }, [boxTopList])

  useEffect (() => {
    setBoxNames(isDropList.length === 0 ? ['Loading', 'Loading', 'Loading'] : isDropList.map(x => x.name))
    setBoxDescrs(isDropList.length === 0 ? ['Loading', 'Loading', 'Loading'] : isDropList.map(x => x.description))
    setBoxVillages(isDropList.length === 0 ? [0, 0, 0] : isDropList.map(x => x.villages))
    setBoxCharacters(isDropList.length === 0 ? [0, 0, 0] : isDropList.map(x => x.characters))
  }, [isDropList])

  const [tooltip, setTooltip] = useState(boxTopList.tooltip != null && boxTopList.tooltip !== '' ? boxTopList.tooltip : 'Increase your reward % by inviting new members.')

  useEffect(() => {
    setTooltip(boxTopList.tooltip != null && boxTopList.tooltip !== '' ? boxTopList.tooltip : 'Increase your reward % by inviting new members.')
  }, [boxTopList])

  const animTooltipShow = useRef()
  const [isTooltip, setIsTooltip] = useState(false)
  const [isTooltipTitle, setIsTooltipTitle] = useState('Edit tooltip')

  useEffect(() => {
    animTooltipShow.current = gsap.timeline({paused: true}).to(q('.admin__drops-textarea'), {
      display: 'block',
      height: '150px',
      padding: '10px',
      duration: 0.5
    })
  }, [])

  useEffect(() => {
    if (isTooltip) {
      setIsTooltipTitle('Close')
      animTooltipShow.current.play()
    } else {
      setIsTooltipTitle('Edit tooltip')
      animTooltipShow.current.reverse()
    }
  }, [isTooltip])


  const animAgreeModal = useRef()

  useEffect(() => {
    animAgreeModal.current = gsap.timeline({paused: true}).to(q('.agree'), {
      opacity: 1,
      display: 'flex',
      duration: 0.5
    })
  }, [])

  const [agreeModalDates, setAgreeModalDates] = useState({
    dataContract: {},
    dropName: '',
    start: '',
    finish: '',
    tooltip: '',
    currency: '',
    minNeed: '',
    maxNeed: '',
    nextDrop: '',
    boxNames: ['1', '2', '3'],
    boxDescrs: ['1', '2', '3'],
    boxVillages: [0, 0, 0],
    boxCharacters: [0, 0, 0],
  })

  async function deployContract() {
    //token, price, characters, villages
    //["0x0000000000000000000000000000000000000000", 1, 20, 20]

    function pow(x, y) {
      let z = 1n
      for (let i = 0; i < y; i++) {
        z *= x
      }
      return z
    }

    const data = isDropList.map((x, i) => {
      return x.price.map((y) => {
        return [buyNft.tokenAddress, (BigInt(y) * pow(10n, buyNft.decimalPlaces)).toString(), boxCharacters[i], boxVillages[i]]
      })
    }).flat()

    console.log(data)

    // if (window.confirm(JSON.stringify(data, null, 4)))
    //   await buyNft.incrementMint(data, currentAccount)
    // else
    //   throw new Error('Cancelled action')

    setAgreeModalDates({
      dataContract: data,
      dropName: name,
      start: createdDate,
      finish: finishDate,
      tooltip: tooltip,
      currency: currency,
      minNeed: minNeed,
      maxNeed: maxNeedTotal,
      nextDrop: nextSaleDate,
      boxNames: boxNames,
      boxDescrs: boxDescrs,
      boxVillages: boxVillages,
      boxCharacters: boxCharacters,
    })
    console.log('start modal')
    animAgreeModal.current.play()
  }

  async function updateDropInfo() {
    const value = boxTopList.status === 'none' ? 'init' : (boxTopList.status === 'init' ? 'open' : (boxTopList.status === 'open' ? 'finish' : (boxTopList.status === 'finish' ? 'init' : '')))

    if (value != "open")
      await api.post(`/admin/drops/status`, {
        value
      })
      
    await BoxsLoading()
    await BoxTopReq()
  }

  return (
    <div className="admin__drops">
      <div className="admin__drops-top">
        <p className="admin__drops-title admin__drops-first">
          <img src={list} alt="" className="admin__drops-img" />
          Created drop
        </p>
        <div className="admin__drops-flex admin__drops-second">
          <img src={starImg} alt="" className="admin__drops-img" />
          <p className="admin__drops-subtitle">
            Drop name:
          </p>
          <input type="text" className="admin__drops-input" value={name} onChange={(e) => {
              setName(e.target.value)
          }}/>
        </div>
        <div className="admin__drops-btns">
          <button className="admin__drops-btn admin__drops-update" onClick={async () => {
              await api.post(`/admin/drops/update?from=${currentAccount}`, {
                name,
                createdDate: new Date(createdDate).getTime(),
                finishDate: new Date(finishDate).getTime(),
                currency,
                minNeed,
                maxNeedTotal,
                nextSaleDate: new Date(nextSaleDate).getTime(),
                boxNames,
                boxDescrs,
                characters: boxCharacters,
                villages: boxVillages
              }, null).then(res => {
                console.log(res) 
              })
              await api.post(`/admin/drops/tooltip?from=${currentAccount}`, {
                tooltip: tooltip,
              }, null).then(res => {
                console.log(res) 
              })
              await BoxsLoading()
              await BoxTopReq()
          }} style={{
            display: boxTopList.status === 'init' ? 'flex' : 'none'
          }}>
            Update
          </button>
          <button className="admin__drops-btn admin__drops-status" onClick={ async () => {
            boxTopList.status === 'init' ? await deployContract() : await updateDropInfo()
          }}>
            {boxTopList.status === 'none' ? 'Create' : (boxTopList.status === 'init' ? 'Start' : (boxTopList.status === 'open' ? 'Finish' : (boxTopList.status === 'finish' ? 'Create' : 'Error')))}
          </button>
        </div>
      </div>
      <div className="admin__drops-block">
        <div className="admin__drops-flex admin__drops-first">
          <img src={flagImg} alt="" className="admin__drops-img" />
          <p className="admin__drops-subtitle">
            Status:
          </p>
          <p className="admin__drops-strong">
            {boxTopList.status === 'finish' ? 'Finished' : boxTopList.status}
          </p>
        </div>
        <div className="admin__drops-flex admin__drops-second">
          <img src={starImg} alt="" className="admin__drops-img" />
          <p className="admin__drops-subtitle">
            Created:
          </p>
          <input type="date" className="admin__drops-input" value={createdDate} onChange={(e) => {
            setCreatedDate(e.target.value)
          }}/>
        </div>
        <div className="admin__drops-flex admin__drops-third">
          <img src={starImg} alt="" className="admin__drops-img" />
          <p className="admin__drops-subtitle">
            Finish date:
          </p>
          <input type="date" className="admin__drops-input" value={finishDate} onChange={(e) => {
            setFinishDate(e.target.value)
          }} />
        </div>
        <div className="admin__drops-fourth admin__drops-tool">
          <button className="admin__drops-tooltip" onClick={async () => {
            setIsTooltip(!isTooltip)
          }}>
            {isTooltipTitle}
          </button>
          <textarea className="admin__drops-textarea" value={tooltip} onChange={(e) => {
            setTooltip(e.target.value)
          }}>
          </textarea>
        </div>
      </div>
      <div className="admin__drops-block">
        <div className="admin__drops-flex admin__drops-first">
          <img src={walletImg} alt="" className="admin__drops-img" />
          <p className="admin__drops-subtitle">
            Currency:
          </p>
          <select className="admin__drops-input admin__drops-select" value={currency} onChange={(e) => {
              setCurrency(e.target.value)
          }} >
            <option value="usdt" className="admin__drops-option">
              USDC
            </option>
          </select>
        </div>
        <div className="admin__drops-flex admin__drops-second">
          <img src={walletImg} alt="" className="admin__drops-img" />
          <p className="admin__drops-subtitle">
            Min need:
          </p>
          <input type="text" className="admin__drops-input" value={minNeed} onChange={(e) => {
              if (e.target.value !== '') {
                if (e.target.value.match(/^\d+$/)) setMinNeed(Number(e.target.value))
              } else {
                setMinNeed('')
              }
          }}/>
        </div>
        <div className="admin__drops-flex admin__drops-third">
          <img src={walletImg} alt="" className="admin__drops-img" />
          <p className="admin__drops-subtitle">
            Max need total:
          </p>
          <input type="text" className="admin__drops-input" value={maxNeedTotal} onChange={(e) => {
              if (e.target.value !== '') {
                if (e.target.value.match(/^\d+$/)) setMaxNeedTotal(Number(e.target.value))
              } else {
                setMaxNeedTotal('')
              }
          }}/>
        </div>
        <div className="admin__drops-flex admin__drops-fourth">
          <img src={starImg} alt="" className="admin__drops-img" />
          <p className="admin__drops-subtitle">
            Next sale:
          </p>
          <input type="date" className="admin__drops-input" value={nextSaleDate} onChange={(e) => {
              console.log(e.target.value)
              setNextSaleDate(e.target.value)
          }}/>
        </div>
      </div>
      <ul className="admin__drops-list">
        {isDropList.map((x, i) => {
          return (
            <li key={i} className="admin__drops-card">
              <div className="admin__drops-box" style={{
                backgroundImage: x.image == null ? `url(${boxImg})` : `url(${x.image})`
              }}/>
              <div className="admin__drops-names">
                <p className="admin__drops-subtitle">
                  Name:
                </p>
                <input type="text" className="admin__drops-input admin__drops-nameboxes" value={boxNames[i]} onChange={(e) => {
                  setBoxNames(
                      boxNames.map((y, j) => {
                          if (i === j) {
                              return e.target.value
                          } else {
                              return y
                          }
                      })
                  )
                }}/>
              </div>
              <div className="admin__drops-price">
                <p className="admin__drops-subtitle">
                  Description:
                </p>
                <textarea type="text" className="admin__drops-input admin__drops-nameboxes admin__drops-descr" value={boxDescrs[i]} onChange={(e) => {
                  setBoxDescrs(
                    boxDescrs.map((y, j) => {
                        if (i === j) {
                            return e.target.value
                        } else {
                            return y
                        }
                    })
                  )
                }} />
              </div>
              <div className="admin__drops-count">
                <div className="admin__drops-counts">
                  <input type="number" className="admin__drops-counter" value={boxVillages[i]} onChange={(e) => {
                    setBoxVillages(
                      boxVillages.map((y, j) => {
                          if (i === j) {
                              return e.target.value
                          } else {
                              return y
                          }
                      })
                    )
                  }} />
                  <p className="admin__drops-counters">
                    Villages
                  </p>
                </div>
                <div className="admin__drops-counts">
                  <input type="number" className="admin__drops-counter" value={boxCharacters[i]} onChange={(e) => {
                    setBoxCharacters(
                      boxCharacters.map((y, j) => {
                          if (i === j) {
                              return e.target.value
                          } else {
                              return y
                          }
                      })
                    )
                  }} />
                  <p className="admin__drops-counters">
                    Characters
                  </p>
                </div>
              </div>
              <div className="admin__drops-price">
                <p className="admin__drops-subtitle">
                  Price categories:
                </p>
                <p className="admin__drops-prices">
                  {x.price[0]}, {x.price[1]}, {x.price[2]} USDC
                </p>
              </div>
              <button className="admin__drops-change" onClick={() => {
                setDateForModal({admin: true, index: i })
                setIsModalDrops(true)
              }}>
                Set prices
              </button>
            </li>
          )
        })}
      </ul>
      <SuccessAdminDrop animAgreeModal={animAgreeModal} isDropList={isDropList} agreeModalDates={agreeModalDates} currentAccount={currentAccount} updateDropInfo={updateDropInfo} />
    </div>
  )
}