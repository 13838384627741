const config = {
    apiEndpoint: "https://rgw.zone/api",
    ref: "https://rgw.zone",
    ws: "wss://rgw.zone/ws",
    chains: {
        ['0x' + (5555).toString(16)]: "Raiders Dev Rpc",
        ['0x' + (1).toString(16)]: "Ethereum",
        ['0x' + (137).toString(16)]: "Polygon",
        ['0x' + (56).toString(16)]: "BNB Smart Chain",
        ['0x' + (8453).toString(16)]: "Base Mainnet",
    }
}
export default config;