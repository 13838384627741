import { truncateAddress } from "../utils";

import { useState, useEffect, useRef, useContext } from "react"

import flagImg from './../assets/img/flag.webp'
import tradeUpImg from './../assets/img/trade-up.webp'
import starImg from './../assets/img/star.webp'
import boxImg from './../assets/img/boxInventory.png'

import { Link } from "react-router-dom";
import api from "../fetch";

import ModalAgreement from "../components/ModalAgreement";
import { PageContext } from "../context.js";
import UserContext from "../contexts/UserContext.jsx";

export default function Drops({boxTopList, setBoxTopList, dateForModal, setDateForModal, isDropList, BoxsLoading, displayRole, name, setIsModalDrops}) {

    const service = useContext(PageContext)

    const {user, setLoad} = useContext(UserContext)

    document.title = 'Raiders • Buy NFT'
    
    const timerStopped = useRef(false)
    const [time, setTime] = useState(`0D 0H 0M 0S`)
    
    async function BoxTopReq() {
        await api.get(`/drops/get`, service.authCallback).then(res => {
            if ((!res.error || res != null) && res.status !== 'none' && res.status !== false) {
                setBoxTopList(res)
            }
        })
    }

    useEffect(() => {
        setLoad(true)
        Promise.all([BoxTopReq(), BoxsLoading()]).then(() => {
            setLoad(false)
        }).catch((err) => {
            console.error(err)
            setLoad(false)
        })
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            const dateLeft = Math.max(0, boxTopList.status ? boxTopList.finishDate - new Date() : 0)
            if (dateLeft === 0) {
              timerStopped.current = true
              clearInterval(interval)
            }
            const days = isNaN(Math.floor(dateLeft / 86400000)) ? 0 : Math.floor(dateLeft / 86400000)
            const hours = isNaN(Math.floor(dateLeft / 3600000 % 24)) ? 0 : Math.floor(dateLeft / 3600000 % 24)
            const minutes = isNaN(Math.floor(dateLeft / 60000 % 60)) ? 0 : Math.floor(dateLeft / 60000 % 60)
            const seconds = isNaN(Math.floor(dateLeft / 1000 % 60)) ? 0 : Math.floor(dateLeft / 1000 % 60)
            setTime(`${days}D ${hours}H ${minutes}M ${seconds}S`)
        }, 1000)

        return () => !timerStopped.current ? clearInterval(interval) : () => {}
    }, [boxTopList])

    useEffect(() => {
        api.addListener('drops', (message) => {
            console.log(message)

            if (message.type == 'totalRaised') {
                BoxTopReq()
            } else if (message.type == 'status') {
                BoxTopReq()
            } else if (message.type == 'boxes') {
                BoxsLoading()
            }
        })

        return () => api.removeListener('drops')
    }, [])

    return (
        <div className="drops">
            <div className="drops__container container">
                <div className="drops__top">
                    <Link to="/dao/voting" className="drops__title title">
                        <div className="title__arrow" />
                        <h2 className="title__descr">
                            Buy NFT box ({boxTopList.name === '' ? 'Loading...' : boxTopList.name})
                        </h2>
                    </Link>
                    <div className="drops__info">
                        <div className="drops__player">
                            <p className="drops__name">
                                {name}
                            </p>
                            <div className="drops__avatar" style={{
                                backgroundImage: user != null && user.avatar !== "url(null)" ? user.avatar : `url(/defaultAvatar.png)`
                            }}/>
                            <p className="drops__address">
                                {service.currentAccount != null ? truncateAddress(service.currentAccount) : 'Loading...'}
                            </p>
                        </div>
                        <p className="drops__rank" style={{
                            color: displayRole === 'Superadmin' ? '#ffff00' : (displayRole === 'Duke' ? '#f40101' : (displayRole === 'Earl' ? '#00ffff' : (displayRole === 'Baron' ? '#00ff00' : '#efefef')))
                        }}>
                            {displayRole}
                        </p>
                        
                        <div className="drops__level">
                            <img className="drops__icon" src={tradeUpImg} alt=''/>
                            <p className="drops__descr">
                                Level:
                            </p>
                            <p className="drops__number">NA</p>
                        </div>
                    </div>
                </div>
                
                <div className="drops__about">
                    <div className="drops__left">
                        <div className="drops__status">
                            <img src={flagImg} alt="" className="drops__img"/>
                            <p className="drops__descr">
                                Order placement status
                            </p>
                            <p className="drops__bold" style={{
                                color: boxTopList.status === "open" ? '#008000' : "#32CD32"
                            }}>
                                {boxTopList.status === 'open' ? 'Open' : 'Buyout'}
                            </p>
                        </div>
                        <div className="drops__created drops__created1">
                            <img src={starImg} alt="" className="drops__img"/>
                            <p className="drops__descr">
                                Started:
                            </p>
                            <p className="drops__bold">
                                {new Date(boxTopList.createdDate).toLocaleDateString('en-US', {month: "short", day: "numeric", year: "numeric"})}
                            </p>
                        </div>
                    </div>
                    <div className="drops__created drops__created2">
                        <img src={starImg} alt="" className="drops__img"/>
                        <p className="drops__descr">
                            Time left:
                        </p>
                        <p className="drops__bold">
                            {time}
                        </p>
                    </div>
                    <div className="drops__last">
                        <button className="drops__btn drops__btn-about">
                            Message from Duke
                            <p className="drops__tooltip">
                                {boxTopList.tooltip != null && boxTopList.tooltip !== '' ? boxTopList.tooltip : 'Read the Constitution!'}
                            </p>
                        </button>
                    </div>
                    <div className="drops__sale drops__about--add">
                        <img src={starImg} alt="" className="drops__img"/>
                        <p className="drops__descr">
                            Next sale:
                        </p>
                        <p className="drops__bold">
                            {boxTopList.nextSaleDate >= (Date.now() * 1000) ? (
                                'TBA'
                            ) : (
                                new Date(boxTopList.nextSaleDate).toLocaleDateString('en-US', {month: "short", day: "numeric", year: "numeric"})
                            )}
                        </p>
                    </div>
                    <div className="drops__last drops__about--add">
                        <Link to="./history" className="drops__btn drops__btn-history">
                            Sales history
                        </Link>
                    </div>
                </div>
                <div className="drops__money">
                    <div className="drops__graph">
                        <div className="drops__graph-some">
                            <div className="drops__graph-line" style={{
                                width: `${boxTopList.totalRaised != null ? boxTopList.totalRaised / ((boxTopList.totalRaised > (Number(boxTopList.maxNeedTotal) + 1) ? boxTopList.totalRaised : (Number(boxTopList.maxNeedTotal) + 1)) / 100) : 0}%`,
                                display: (boxTopList.totalRaised != null) ? 'block' : 'none',
                                backgroundColor: boxTopList.totalRaised < boxTopList.minNeed ? '#ff0000' : (boxTopList.totalRaised >= boxTopList.minNeed && boxTopList.totalRaised <= boxTopList.maxNeedTotal ? '#008000' : (boxTopList.totalRaised > boxTopList.maxNeedTotal ? '#e2e220' : 'transparent'))
                            }} />
                        </div>
                        <p className="drops__graph-total drops__graph-descr" style={{
                            left: `${boxTopList.totalRaised != null ? boxTopList.totalRaised / ((boxTopList.totalRaised > (Number(boxTopList.maxNeedTotal) + 1) ? boxTopList.totalRaised : (Number(boxTopList.maxNeedTotal) + 1)) / 100) : 0}%`,
                            display: (boxTopList.totalRaised != null) ? 'block' : 'none'
                        }}>
                           orders: {boxTopList.totalRaised}
                        </p>
                        <p className="drops__graph-min drops__graph-descr" style={{
                            left: `${boxTopList.minNeed != null ? boxTopList.minNeed / ((boxTopList.totalRaised > (Number(boxTopList.maxNeedTotal) + 1) ? boxTopList.totalRaised : (Number(boxTopList.maxNeedTotal) + 1)) / 100) : 0}%`,
                            bottom: 'calc(100% + 10px)',
                            display: boxTopList.minNeed != null ? 'block' : 'none'
                        }}>
                            {boxTopList.minNeed != null ? `min: ${boxTopList.minNeed}` : `min 0`}
                        </p>
                        <p className="drops__graph-max drops__graph-descr" style={{
                            top: `${((boxTopList.maxNeedTotal != null ?  boxTopList.maxNeedTotal / ((boxTopList.totalRaised > (Number(boxTopList.maxNeedTotal) + 1) ? boxTopList.totalRaised : (Number(boxTopList.maxNeedTotal) + 1)) / 100) : 0) - (boxTopList.minNeed != null ? boxTopList.minNeed / ((boxTopList.totalRaised > (Number(boxTopList.maxNeedTotal) + 1) ? boxTopList.totalRaised : (Number(boxTopList.maxNeedTotal) + 1)) / 100) : 0)) < 10 ? 'calc(100% + 10px)' : 'auto'}`,
                            bottom: `${((boxTopList.maxNeedTotal != null ?  boxTopList.maxNeedTotal / ((boxTopList.totalRaised > (Number(boxTopList.maxNeedTotal) + 1) ? boxTopList.totalRaised : (Number(boxTopList.maxNeedTotal) + 1)) / 100) : 0) - (boxTopList.minNeed != null ? boxTopList.minNeed / ((boxTopList.totalRaised > (Number(boxTopList.maxNeedTotal) + 1) ? boxTopList.totalRaised : (Number(boxTopList.maxNeedTotal) + 1)) / 100) : 0)) > 10 ? 'calc(100% + 10px)' : 'auto'}`,
                            left: `${boxTopList.maxNeedTotal != null ?  boxTopList.maxNeedTotal / ((boxTopList.totalRaised > (Number(boxTopList.maxNeedTotal) + 1) ? boxTopList.totalRaised : (Number(boxTopList.maxNeedTotal) + 1)) / 100) : 0}%`,
                            display: boxTopList.maxNeedTotal != null ? 'block' : 'none'
                        }}>
                            {boxTopList.maxNeedTotal != null ? `max: ${boxTopList.maxNeedTotal}` : `max 0`}
                        </p>
                        <div className="drops__graph-min--line" style={{
                            left: `${boxTopList.minNeed != null ? boxTopList.minNeed / ((boxTopList.totalRaised > (Number(boxTopList.maxNeedTotal) + 1) ? boxTopList.totalRaised : (Number(boxTopList.maxNeedTotal) + 1)) / 100) : 0}%`,
                            display: boxTopList.minNeed != null ? 'block' : 'none'
                        }}/>
                        <div className="drops__graph-max--line" style={{
                            left: `${boxTopList.maxNeedTotal != null ?  boxTopList.maxNeedTotal / ((boxTopList.totalRaised > (Number(boxTopList.maxNeedTotal) + 1) ? boxTopList.totalRaised : (Number(boxTopList.maxNeedTotal) + 1)) / 100) : 0}%`,
                            bottom: `${((boxTopList.maxNeedTotal != null ?  boxTopList.maxNeedTotal / ((boxTopList.totalRaised > (Number(boxTopList.maxNeedTotal) + 1) ? boxTopList.totalRaised : (Number(boxTopList.maxNeedTotal) + 1)) / 100) : 0) - (boxTopList.minNeed != null ? boxTopList.minNeed / ((boxTopList.totalRaised > (Number(boxTopList.maxNeedTotal) + 1) ? boxTopList.totalRaised : (Number(boxTopList.maxNeedTotal) + 1)) / 100) : 0)) > 10 ? '0' : '-5px'}`,
                            display: boxTopList.maxNeedTotal != null ? 'block' : 'none'
                        }}/>
                    </div>
                    <div className="drops__sale">
                        <img src={starImg} alt="" className="drops__img"/>
                        <p className="drops__descr">
                            Next sale:
                        </p>
                        <p className="drops__bold">
                            {boxTopList.nextSaleDate >= (Date.now() * 1000) ? (
                                'TBA'
                            ) : (
                                new Date(boxTopList.nextSaleDate).toLocaleDateString('en-US', {month: "short", day: "numeric", year: "numeric"})
                            )}
                        </p>
                    </div>
                    <div className="drops__last">
                        <a href="https://constitution.rgw.zone/how-to-earn..." target="_blank" className="drops__btn drops__btn-history">
                            read.me
                            <p className="drops__tooltip">
                            A detailed explanation of why you should buy a box now!
                            </p>
                        </a>
                    </div>

                </div>
                <div className="drops__cards">
                    {!service.load && isDropList != null && isDropList.length > 0 && isDropList.map((x, i) => {
                        return (
                            <div key={i} className="drops__card">
                                {x.status.includes("approved") && <div className="drops__card_alert">
                                    !
                                </div>}
                                <div className="drops__card-img" style={{
                                    backgroundImage: x.image == null ? `url(${boxImg})` : `url(${x.image})`
                                }} onClick={() => {
                                    if (service.currentAccount != null && boxTopList.status === 'open') {
                                        setDateForModal({admin: false, index: i })
                                        setIsModalDrops(true)
                                    }
                                }}/>
                                <div className="drops__card-block">
                                    <p className="drops__descr drops__card-subtitle">
                                        Name:
                                    </p>
                                    <p className="drops__card-title">
                                        {x.name}
                                    </p>
                                </div>
                                <div className="drops__card-block drops__card-block--ai">
                                    <p className="drops__descr drops__card-subtitle">
                                        Description:
                                    </p>
                                    <p className="drops__card-descr" dangerouslySetInnerHTML={{__html: x.description.replaceAll('\n', '<br/>') }} />
                                </div>
                                <div className="drops__card-block drops__card-prices">
                                    <p className="drops__descr drops__card-subtitle">
                                        Price category:
                                    </p>
                                    <p className="drops__bold drops__price">
                                        {x.price[0]}, {x.price[1]}, {x.price[2]} {boxTopList.currency}
                                    </p>
                                </div>
                                <div className="drops__card-bottom">
                                    <button className={`drops__card-btn`} onClick={async () => {
                                        if (service.currentAccount != null && boxTopList.status !== false) {
                                            setDateForModal({admin: false, index: i, loading: true })
                                            setIsModalDrops(true)
                                        }
                                    }} style={{
                                        opacity: service.currentAccount == null || boxTopList.status === false ? 0.6 : 1
                                    }}>
                                        { boxTopList.status === false ? 'No sales currently' : service.currentAccount == null ? 'Not authorized' : (dateForModal.loading && dateForModal.index == i ? 'Loading...' : 'Place your order') }
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            {service.currentAccount != null && service.role !== -1 && service.agreement == null && <ModalAgreement />}
        </div>
    )
}